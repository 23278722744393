.theme-toggler {
  width: 48px;
  height: 48px;
  padding: 12px;
  border: none;
  outline: none;
  background: transparent;
  color: var(--primary);
  cursor: pointer;

  svg {
    width: 100%;
  }
}

:root {
  --main-bg-color: #fff;
  --primary: #1f1f1f;
  --scanline-opacity: 0.065;
}

[data-theme='dark'] {
  --main-bg-color: #1f1f1f;
  --primary: #fff;
  --scanline-color: #1f1f1f;
  // --scanline-opacity: 0.25;
}

.home {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
  position: relative;
  background: var(--main-bg-color);

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.content {
  padding: 56px 72px;
  color: var(--primary);

  .title {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 4.6rem;
    line-height: 4.2rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: -10px;
    letter-spacing: 0.01rem;
  }

  .text {
    font-family: "Cutive Mono", monospace;
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 12px;

    &--sm {
      font-size: 1.2rem;
    }

    a {
      text-decoration: none;
      color: #ea5f57;

      &:hover {
        color: #ea5f57;
        text-decoration: line-through;
        border-bottom-color: transparent;
      }
    }
  }

  .emoji {
    margin-top: 24px;
    margin-bottom: 128px;
  }

  @media (max-width: 768px) {
    padding: 32px 24px;

    .title {
      font-size: 4rem;
      line-height: 3.6rem;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.filter {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 1;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: var(--scanline-color);
    // background: #1f1f1f;
    // opacity: var(--scanline-opacity);
    animation: scanline 8s linear infinite;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 50%, rgba(18, 31, 31, 0.25) 50%),
      linear-gradient(90deg, rgba(255, 31, 31, 0.06), rgba(18, 255, 31, 0.02), rgba(18, 31, 255, 0.06));
    background-size: 100% 2px, 3px 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  padding-top: 32px;
  padding-right: 32px;

  @media (max-width: 768px) {
    justify-content: flex-end;
    padding: 0;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 0 0 24px;
    box-sizing: border-box;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    @media (max-width: 768px) {
      &::after {
        content: "GitHub"; 
      }

      display: flex;
      align-items: center;
      gap: 10px;
      width: initial;
      text-decoration: none;
      color: #ea5f57;

      &:hover {
        color: #ea5f57;
        text-decoration: line-through;
        border-bottom-color: transparent;
      }
    }
  }
}

@keyframes scanline {
  0% {
    transform: translateY(150vh);
  }
  100% {
    transform: translateY(-300vh);
  }
}
